'use strict';

(function() {
  function WOPackageResource($resource) {
    return $resource('api/v1/wopackages/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      create: {
        method: 'POST'
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('WOPackage', WOPackageResource);

})();
